import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiBox , FiUsers , FiMonitor ,FiChevronUp, FiLayout, FiFacebook, FiBookOpen, FiUserCheck, FiEdit} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiLayout />,
        title: 'Publishing',
        description: 'Book and magazine design and layout, illustration and advanced photo compositing.'
    },
    {
        icon: <FiBox />,
        title: 'Branding',
        description: 'Logo and Identity design, custom typography, brand collateral design and branded product design.'
    },
    {
        icon: <FiMonitor />,
        title: 'Digital design',
        description: 'Advanced retouching, product design and development, creative and innovative design for print and web.'
    }
]

const ServiceList2 = [
    {
        icon: <FiUsers />,
        title: 'Consulting & project management ',
        description: 'Our decade-long experience in marketing and publishing helps our clients shape their ideas and create great products.'
    },
    {
        icon: <FiLayers />,
        title: 'Tie-ins',
        description: 'Cross-branding with other companies and brands in order to achieve a dual campaign effects.'
    },
    {
        icon: <FiFacebook />,
        title: 'Advertising',
        description: 'Promoting brands and services through a vast network of printed and electronic media.'
    }
]

const ServiceList3 = [
    {
        icon: <FiBookOpen />,
        title: 'Translation ',
        description: 'Translation to and from more than 15 languages.'
    },
    {
        icon: <FiEdit />,
        title: 'Editing',
        description: 'Scientific and book editing in English, Serbian, Croatian and Slovenian.'
    },
    {
        icon: <FiUserCheck />,
        title: 'Proofreading',
        description: 'Native speaker proofreading in English, Serbian, Croatian and Slovenian.'
    }
]


class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Our services'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Graphic design</h2>
                                    <p>We ensure your project communicates with your audience, <br /> using every last pixel to focus on delivering the right messages.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Marketing</h2>
                                    <p>Whether you need digital marketing services, advertising or sponsorship tie-ins, <br /> Stardust will be here to cater to all of your promotional needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Language services</h2>
                                    <p>Our network includes in-country language professionals including <br /> translators, copywriters, scientific advisers, etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList3.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;
