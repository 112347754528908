import React, { Component } from "react";

const PortfolioListContent = [
  {
      image: 'image-2',
      category: 'Brand identity',
      title: ' Logo and branding for Atcom GT'
  },
  {
      image: 'image-1',
      category: 'Graphic design',
      title: ' Book designs for Vesna Dedić'
  },
  {
      image: 'image-3',
      category: 'Marketing/Development',
      title: ' HTML-5 ad campaign for Cineplexx'
  },
  {
      image: 'image-5',
      category: 'Graphic design',
      title: ' Book designs for Čarobna knjiga'
  },
  {
      image: 'image-4',
      category: 'Brand identity',
      title: ' Brand design for NextLinks'
  },
  {
      image: 'image-6',
      category: 'Graphic design',
      title: ' Promotional campaigns for Češalj & Makaze'
  },
  {
      image: 'image-7',
      category: 'Packaging design',
      title: ' Brand and packaging design for Crvene kapi'
  },
  {
      image: 'image-8',
      category: 'Graphic design',
      title: ' Book designs for Pi-Press'
  },
  {
      image: 'image-9',
      category: 'Graphic design',
      title: ' Book designs for Vulkan'
  }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`/portfolio-details-${index}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`/portfolio-details-${index}`}>View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;
