import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About Stardust',
        description = 'We work with forward thinking clients to create beautiful, honest and amazing things that bring positive results.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>Stardust LAB is a design, language and user-focused digital agency based in Belgrade, Serbia. We produce intelligent designs, deliver engaging experiences and high quality language work and build meaningful connections. We work with great clients all over the world to create thoughtful and purposeful products and publications. </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">What we do</h3>
                                                <p>We specialise in great service, innovative designs, faithful translations and engaging interactive experiences. Above all, we believe in quality, attention to detail, interaction, experimentation, collaboration, exploration and everything digital.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
