import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Stardust LAB have been a trusted partener for many years - people I can always rely on for creative work and a straightforward approach.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Vesna Dedić </span> - Bestselling author</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Over the years, Stardust LAB have worked with us on everything from brand design, copy, online and print. Projects are always approached with enthusiasm, care and a focus to deliver on-time and within the agreed budget.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Dubravka Trišić </span> - Executive editor at Vulkan Publishing</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We have collaborated with Stardust LAB multiple times over the years. I highly recommend them. We have had ZERO issues on any of the work they have produced.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>JC Pohl </span> - CEO at TEENTRUTH</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>When professionalism meets knowledge, then you know that you work with the Stardust team! This is the factor for implementing the most demanding projects in Greece and Balkans.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Tania Skandalaki </span> - Senior Editor & Publishing Project Manager at DeAgostini SEE</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Always a pleasure to work with Stardust LAB. Such professional and kind people and you know you’ll receive a quick, innovative and no fuss service.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Bojan Savić </span> - CEO at Češalj & Makaze</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
